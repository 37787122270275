import { EColors } from 'common/styles'

import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledCopyRight } from './styled'

type CopyRightProps = {
  style?: CSSProperties
}

export const CopyRight = ({ ...props }: CopyRightProps) => {
  const { t } = useTranslation()
  return (
    <>
      <StyledCopyRight {...props} color={EColors.neutral_600}>
        {t('copy.right')}
      </StyledCopyRight>
    </>
  )
}
