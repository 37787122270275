import { Svg } from 'common/assets'
import { useResolution } from 'common/hooks'
import { useTranslation } from 'react-i18next'
import {
  Container,
  FranchiseText,
  FranchiseWrapper,

} from './styled'

export const Franchise = () => {
  const { t } = useTranslation()
  const { isMinScreen768 } = useResolution()

  return (
    <>
      <Container>
        <FranchiseWrapper>
          <FranchiseText>{t('franchise')}</FranchiseText>
          {isMinScreen768 && <img src={Svg.SignThree} />}
        </FranchiseWrapper>
      </Container>
    </>
  )
}
