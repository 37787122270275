import { Png } from 'common/assets'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import styled from 'styled-components'

export const Section = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${Png.BookBg});
 
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: left 45% center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  overflow: hidden;
  @media screen and (min-width: 551px) {
  background-attachment: fixed;
  background-position: center bottom;
  }
`
export const Title = styled(Styled.H3)`
  color: ${EColors.white};
  width: 100%;
  max-width: 1076px;
  text-align: center;
  padding: 0 72px;
   @media screen and (max-width: 550px) {
    padding: 0 16px;
  }
`
