import { EColors } from "common/styles";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MottoWrapper = styled.div<{
  maxWidth: string
}>`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.4s linear 0.1s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 449px) {
    margin-left: 0;
    margin-bottom: 12px;
  }
  @media screen and (min-width: 1344px) {
    max-width: ${({maxWidth})=>maxWidth};
  }
  & img {
    position: absolute;
    bottom: -50%;
    right: -1%;
  }
  
   @media screen and (max-width: 460px) {
  & img {  
   top: -25%;
    right: -1%;
  }
   }
    @media screen and (max-width: 767px) {
  & img {  
  width: 64px;
  transform: rotate(-5deg);
   
  }
   }
     @media screen and (min-width: 768px) and (max-width:1143px)  {
   & img{
    width: 7.5vw;
    height: auto;
  }
  }
    @media screen and (min-width: 768px) and (max-width:1199px)  {
   & img{
    right:15%;
   }
  }
`;

export const StyledSpan = styled.span`
font-family: "Caslon 540 BT";
font-size: 68px;
 color: ${EColors.primary_500};
  white-space: pre-line;
   @media screen and (max-width: 767px) {
    font-size: 36px;
    line-height: 106%;
  }
 @media screen and (min-width: 768px) {

 text-transform: uppercase;
  letter-spacing: 0.36px;
 }
  @media screen and (min-width: 768px)and (max-width: 1439px) {
  font-size: calc((100vw - 768px) / (1439 - 768) * (68 - 36) + 36px);
  }
`