import { createGlobalStyle } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import { EColors } from './colors'

export const GlobalStyles = createGlobalStyle`


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box ;

}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color:${EColors.bg_light};
    scroll-behavior: smooth;
}

body.hidden{
    overflow:hidden!important;
}

`
