import { Styled } from "components/Styled"
import { Trans } from 'react-i18next'

export const TextContent=()=>{
    return(
        <Styled.FlexWrapper direction="column">
        <Styled.PText>
        <Trans i18nKey="text.about.us"  />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.one" />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.two" />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.three"  />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.four" />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.five" />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.six"  />
        </Styled.PText>
        <Styled.PText>
        <Trans i18nKey="text.about.us.seven"  />
        </Styled.PText>
      </Styled.FlexWrapper>
    )
}