import { EColors } from "common/styles";
import styled from "styled-components";

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 100px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;
export const StyledTextContent = styled.div`
  display: block;
`;
export const PoliticsWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding: 50px 100px;
  background-color: rgb(248, 248, 248);
  overflow-y:scroll;
  z-index: 4;
  @media screen and (max-width: 767px) {
    padding: 30px 20px;
  }
`;
