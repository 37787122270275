import styled from "styled-components";

export const StyledTitle = styled.h3`
font-family: 'Circe';
font-size: 16px;
line-height: 147%;
color:#B68D88;
@media screen  and (max-width:767px){
   font-size: 14px; 
}
`