import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { GeneralMenu } from "modules/GeneralMenu";
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 12;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
 @media screen and (min-width: 1000px) {
    height: calc(100vh - 120px);
  }
  @media screen and (min-width: 1200px) {
    height: calc(100vh - 166px);
  }

`

export const Link = styled.a`
  text-decoration: none;
`

export const Item = styled.h1`
  font-family: 'Alegreya';
  font-size:70px;
  line-height: 60px;
  color: ${EColors.primary_100};
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${EColors.primary_500};
  }
  @media screen and (max-width: 550px) {
      font-size:36px;
  }
  @media screen and (max-width:999px) {
      font-size:50px;
  }
`
export const StyledGeneralMenu = styled(GeneralMenu)`
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    gap: 14px;
  }
`