import { EColors } from "common/styles";
import styled from "styled-components";

export const StyledCopyRight = styled.p`
font-family: 'Old Standard Regular';
font-weight:400;
font-size: 16px;
color: ${EColors.neutral_600};
@media screen and (max-width: 767px){
font-size: 16px;
line-height:140%;
}
`