import axios from 'axios'
import { baseURL, initialLanding, TLanding} from 'models'
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react'

type LandingProps = {
    children: ReactNode
}

type LandingContextProps = {
    landing: TLanding
    setLanding: Dispatch<SetStateAction<TLanding>>
}

export const LandingContext = createContext<LandingContextProps>({
    landing: initialLanding,
    setLanding: () => { },
})


export const LandingProvider = ({ children }: LandingProps) => {
    const [landing, setLanding] = useState<TLanding>(initialLanding)

    const value = {
        landing,
        setLanding,
    }

    const fetchLanding = async (name:string) => {
        try {
            await axios.get(`${baseURL}/api/landings/${name}`)
                .then((response) => setLanding(response.data));
        } catch(e) {
            throw Error
        }
    }

    useEffect(() => {
        fetchLanding('cafe-centaur')
    }, [])
    
    return (
        <LandingContext.Provider value={value}>
            {children}
        </LandingContext.Provider>
    )
}
