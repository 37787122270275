import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 999px) {
    gap: 20px;
  }
`

export const ContentWarpper = styled.div`
  width: 100%;

  display: flex;
  gap: 30px;
  align-items: baseline;

  @media screen and (max-width: 999px) {
    flex-direction: column;
    gap: 4px;
  }
`

export const TitleWrapper = styled.div`
  width: 50%;

  display: flex;
  gap: 15px;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 999px) {
    width: 100%;
  }
`

export const InfoWrapper = styled.div`
  width: 50%;

  display: flex;
  gap: 15px;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 999px) {
    width: 100%;
  }
`
export const StyledText = styled.p`
font-family: 'Circe';
font-weight: 600;
font-size: 16px;
line-height: 147%;
color: ${ EColors.primary_500 };
@media screen and (max-width: 767px) {
  font-size: 14px;
  line-height: 140%;
  color: #B68D88;
}
`

