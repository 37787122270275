import { Config } from 'common/config'
import { MenuContext } from 'common/contexts/Menu'
import { useIntersection, useResolution } from 'common/hooks'
import { FlexWrapper } from 'components/Styled/Styled'
import { useContext, useRef } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Component } from '..'
import { Container, Item, Link, StyledGeneralMenu } from './styled'

export const Menu = () => {
  const { t } = useTranslation()
  const { isMinScreen1000 } = useResolution()
  const { setIsOpen } = useContext(MenuContext)
  const ref = useRef(null)
  const isInteract = useIntersection({ element: ref })

  const items = [
    {
      title: t('about.us'),
      href: `#${Config.Section.aboutUs}`,
    },
    {
      title: t('other.bookmarks'),
      href: `#${Config.Section.anotherRestaurants}`,
    },
    {
      title: t('contacts'),
      href: `#${Config.Section.contacts}`,
    },
  ]

  const onItemClick = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Container ref={ref}>
        {!isMinScreen1000 && (<StyledGeneralMenu />)}
        <FlexWrapper direction='column' justify='center' gap={isMinScreen1000 ? 56: 14}>
        <Fade
          cascade
          triggerOnce
          direction="up"
          duration={500}
          damping={0.5}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems:"center",
            opacity: isInteract ? "1" : "0",
          }}
        >
       
          {items.map((item, index) => (
            
            <Link href={item.href} key={index} onClick={onItemClick}>
              <Item>{item.title}</Item>
            </Link>
        
          ))}
          </Fade>
          </FlexWrapper>
        <Component.Footer />
      </Container>
    </>
  )
}
