import BookBg from './sources/book_bg.png'
import Libraria from './sources/libraria.png'
import MonsPins from './sources/mons_pins.png'
import ParaDjanow from './sources/para_djanow.png'
import BaboGarden from './sources/babo_garden.png'
import Centaur from './sources/centaur.png'
import ModalBg from './sources/modal_bg.png'
import App from './sources/app_store.png'
import Google from './sources/google_play.png'

export const Png = {
  BookBg,
  Libraria,
  MonsPins,
  ParaDjanow,
  Centaur,
  BaboGarden,
  ModalBg,
  App,
  Google
}
