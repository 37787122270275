import { useResolution } from 'common/hooks'
import { Header } from 'modules/Header'
import { useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Component } from './components'
import { Container } from './styled'

export const Menu = () => {
  const { isMinScreen1000 } = useResolution()


  return (
      <Container >
          <Header />
          {isMinScreen1000 && <Component.Background />}
          <Component.Menu />
      </Container>
  )
}
