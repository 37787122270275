import { Png, Svg } from "common/assets";
import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  background-color: ${EColors.bg_light};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 52px;
  background-image: url('${Svg.SignFour}');
  background-repeat: no-repeat;
  background-position: right 16px bottom -4%;
  background-size:83px;
  @media screen and (min-width: 768px) {
    height: calc(100vh - 100px);
  }
   @media screen and (min-width: 768px) {
    height: calc(100% + 22px);
      background-position: left 34% bottom -8.5%;
  background-size:114px;
   }
  @media screen and (min-width: 1000px) {
    height: calc(100vh - 120px);
    min-height: 808px;
  }
  @media screen and (min-width: 1200px) {
    height: calc(100vh - 166px);
  }

`;

export const MottoInfoWrapper = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.5s linear 0.3s forwards;
  @media screen and (min-width: 1000px) {
    position: relative;
    align-items: flex-end;
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    gap: 24px;
  }
  @media screen and (min-width: 1351px) {
    gap: 50px;
  }

  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 767px) {
& img{
  width: 21.8vw;
  height: auto;
  margin-left:auto;
}
  }

`;

export const MottoInfo = styled.p`
  width: 100%;
  max-width: 526px;
  font-family: "Circe",sans-serif;
  font-weight:400;
  font-size: 18px;
  line-height: 144%;
  color: ${EColors.neutral_800};
  

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const ButtonDown = styled.button`
  border: 1px solid ${EColors.primary_500};
  border-radius: 400px;
  width: 128px;
  height: 128px;

  background-color: transparent;
  background-image: url(${Svg.ArrowDown});
  background-repeat: no-repeat;
  background-size: 33.3px 48.8px;
  background-position: 50% 50%;
  opacity: 0;
  cursor: pointer;
  transition: all 0.4s linear;
  animation: appearance 3s linear 0.5s forwards;
  margin: 0 auto;
  margin-top: 34px;
  &:hover {
    border-color: transparent;
  }
  @media screen and (max-width: 1439px) {
    width: 100px;
    height: 100px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1350px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin-top: 16px;
  }
  @media screen and (min-width: 1351px) {
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%);
    margin-top: 0;
  }
  @keyframes appearance {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ContantContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding:0 72px;
  @media screen and (max-width: 1199px) {
    padding:0 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  @media screen and (max-width: 999px) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 14px;
  }
  @media screen and (max-width: 999px) {
    gap: 26px;
  }

  @media screen and (min-width: 1000px) {
    position: relative;
  }
`;
export const StyledBrand = styled.img`
  width: 58px;
  height: 52px;
  margin: 12px auto 8px auto;
  @media screen and (min-width: 768px) {
    width: 80px;
    height: 72px;
    margin: 8px auto 44px auto;
  }
`;
