import { Svg } from 'common/assets'
import { EColors } from 'common/styles'
import { FONT } from 'common/utils'
import { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { StyledInputProps } from './types'

const isChrome = navigator.appVersion.indexOf('Chrome/') != -1

export const Input = styled.input<StyledInputProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding-bottom: ${({ height }) => parseFloat(`${height}`) - 21}px;
  position: relative;
  background-color: transparent;

  border: none;
  border: 0px solid ${EColors.neutral_300};
  border-bottom-width: 1px;
  outline: none;

  ${FONT({ color: EColors.neutral_800, weight: '500', size: '16px' })}

  &::placeholder {
    color: ${EColors.neutral_600};
    text-transform: uppercase;
  }

  -moz-appearance: textfield;
  appearance: textfield;

  ::-webkit-calendar-picker-indicator {
    filter: ${EColors.filter_neutral_600};
    opacity: 1;
    background-image: url(${Svg.ArrowDownNoLine});
    width: 19px;
    height: 11px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 0.5rem;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const InputWrapper = styled.div<{
  type: InputHTMLAttributes<HTMLInputElement>['type']
}>`
  position: relative;
  width: 100%;

  input[type='date']:not(.has-value):before {
    color: lightgray;
    content: attr(placeholder);
  }

  ${({ type }) =>
    ((!isChrome && (type === 'date' || type === 'time')) ||
      type === 'number') &&
    css`
      ::after {
        position: absolute;
        content: '';
        filter: ${EColors.filter_neutral_600};
        opacity: 1;
        background-image: url(${Svg.ArrowDownNoLine});
        width: 19px;
        height: 11px;
        cursor: pointer;
        right: 1px;
        top: 0px;
      }
    `}
`
export const Error = styled.p`
  position: absolute;
  top: 38px;
  width: 100%;
  height: 5px;
  ${FONT({
    size: "14px", weight: "300", color: "#ED1C24"
})}
`;
