import Facebook from './sources/Facebook.svg'
import Instagram from './sources/Instagram.svg'
import ArrowDown from './sources/ArrowDown.svg'
import Cross from './sources/Cross.svg'
import ArrowDownNoLine from './sources/ArrowDownNoLine.svg'
import Menu from './sources/Menu.svg'
import Logo from './sources/Logo.svg'
import Ellipse from './sources/Ellipse.svg'
import FilledEllipse from './sources/FilledEllipse.svg'
import MenuLine from './sources/menu_line.svg'
import Success from './sources/success.svg'
import Error from './sources/error.svg'
import sprite from './sources/sprite.svg'
import Close from './sources/close.svg'
import SignOne from './sources/sign_one.svg'
import SignTwo from './sources/sign_two.svg'
import SignThree from './sources/sign_three.svg'
import SignFour from './sources/sign_four.svg'

export const Svg = {
  Facebook,
  Instagram,
  ArrowDown,
  Cross,
  ArrowDownNoLine,
  Menu,
  Logo,
  Ellipse,
  FilledEllipse,
  MenuLine,
  Success,
  Error,
  sprite,
  Close,
  SignOne,
  SignTwo,
  SignThree,
  SignFour,
}
