import { EColors } from "common/styles";
import { CopyRight } from "components/CopyRight";
import { GeneralMenu } from "modules/GeneralMenu";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;

  @media screen and (max-width: 1000px) {
    justify-content:center;
    order: 3;
    margin-top: 52px;
    gap: 50px;
    
  }
`;

export const Link = styled.a`
cursor: pointer;
  p {
    color: ${EColors.neutral_600};
  }
  text-decoration: none;
`;

export const StyledGeneralMenu = styled(GeneralMenu)`
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    gap: 20px;
  }
 @media screen and (min-width: 1001px)  and (max-width: 1439px) {
    margin-bottom:20px;
  }
  @media screen and (min-width: 1440px) {
    flex-direction: row;
  }
`;

export const PolicyPrivacyContainer = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const StyledCopyRight = styled(CopyRight)`
  display: none;
  align-self: center;
  margin-top: 12px;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
`;
export const StyledWrapper = styled.div`
display:flex;
flex-direction:column;

@media screen and (min-width: 768px) and (max-width:1000px)  {
  display:flex;
  align-items:center;
  gap:20px;
}
@media screen and (min-width: 1001px) {
  flex-direction:row;
  align-items:flex-start;
  gap:56px;
}
`
export const StyledLinkText = styled.p`
font-family: 'Old Standard Regular';
font-weight:400;
font-size: 16px;
color: ${EColors.neutral_600};
@media screen and (max-width: 767px){
font-size: 16px;
line-height:140%;
}
`
