import { Svg } from "common/assets"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "./LoadingButton"
import {  LoadAppWrapper, StyledButtonWrapper, StyledTitle } from "./styled"

export const LoadApp = () => {
    const { t } = useTranslation()
    
    const handleAppClick = () => {
        window.open(
            'https://apps.apple.com/us/app/restaron/id6447766070'
        )
    }

    const handleGoogleClick = () => {
        window.open(
            'https://play.google.com/store/apps/details?id=com.kitapp.restaron'
        )
    }
    return (
        <LoadAppWrapper>
            <StyledTitle>{t('loading-title')}</StyledTitle>
            <StyledButtonWrapper>
                <LoadingButton src={Svg.sprite + "#icon-apple"} handleClick={handleAppClick} text="App Store"/>
                <LoadingButton src={Svg.sprite + "#icon-google"} handleClick={handleGoogleClick} text="Google play"/>
            </StyledButtonWrapper>

        </LoadAppWrapper>
    )
}