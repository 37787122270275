import { LandingContext } from 'common/contexts/Landing/Landing'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { RowInfo } from 'components/RowInfo'
import { Styled } from 'components/Styled'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTitle } from './styled'

export const Info = () => {
  const { t } = useTranslation()
  const { landing } = useContext(LandingContext);
  const language = localStorage.getItem("Localization") ?? "uk";
  const data =  [
      {
        title: t('addresses'),
        Info: (
          <>
            <Styled.H3 color={EColors.primary_500}>
              {t('contact.address')}
            </Styled.H3>
          </>
        ),
      },
      {
        title: t('work.time'),
        Info: (
          
            <Styled.FlexWrapper   direction='column' gap={24} align='flex-start'>
            <Styled.H3 color={EColors.primary_500}>
              {language === "uk" ? landing?.workingTime[0].day[0].value : landing?.workingTime[0].day[1].value}
              &nbsp;
              {`${landing?.workingTime[0].start}` + "-" + `${landing?.workingTime[0].end}`}
            </Styled.H3>
            {landing?.workingTime[1] && (<Styled.H3  color={EColors.primary_500}>
              {language === "uk" ? landing?.workingTime[1].day[0].value : landing?.workingTime[1].day[1].value}
              &nbsp;
              {`${landing?.workingTime[1].start}` + "-" + `${landing?.workingTime[1].end}`}
            </Styled.H3>
            )}
            </Styled.FlexWrapper>
        ),
      },
      {
        title: t('phone'),
        Info: (
          <>
            <Styled.H3 color={EColors.primary_500}>
              {t('contact.phone')}
            </Styled.H3>
          </>
        ),
      },
      {
        title: t('social.media'),
        Info: (
          <>
            <Button.SocialMedia />
          </>
        ),
      },
    ]

  return (
    <>
      <Styled.FlexWrapper
        width="100%"
        direction="column"
        gap={28}
        style={{ marginTop: '48px' }}>
        {data.map((item, index) => (
          <RowInfo
            key={index}
            Info={item.Info}
            Title={
              <StyledTitle >
                {item.title}
              </StyledTitle>
            }
          />
        ))}
      </Styled.FlexWrapper>
    </>
  )
}
