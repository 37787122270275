import { Styled } from 'components/Styled'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const FormContainer = styled.div`
  max-width: 100%;
  width: 651px;
  margin-top: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StatusImage = styled.img`
  width: 216px;
  margin-bottom: 56px;

  @media screen and (max-width: 550px) {
    width: 180px;
    margin-bottom: 36px;
  }
`

export const TextInfo = styled(Styled.PBody1)`
  margin-top: 48px;
  margin-bottom: 36px;
  text-align: center;

  @media screen and (max-width: 550px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
