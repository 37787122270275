export const General = {
  instagram: 'https://www.instagram.com/centaura.restaron ',
  facebook: 'https://www.facebook.com/share/zFczNfNaig9Rxyfv/?mibextid=LQQJ4d',
  googleApiKey: 'AIzaSyAfq0_75Gl5XXb9En8dlz7LdPQhftS8XH0',
  parajanov: 'https://parajanov.com.ua/',
  babogardens: 'https://babogardens.com.ua/',
  monspius: 'https://monspius.com.ua/',
  libraria: 'https://libraria.com.ua/',
  phone: '(096) 188-24-45',
}
