import { EColors, EFont } from 'common/styles'
import styled from 'styled-components'
import { TFlexWrapper, THr, TText, TTextProps } from './types'

export const FlexWrapper = styled.div<TFlexWrapper>`
  display: flex;
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  gap: ${({ gap }) => (gap ? `${gap}px` : '0px')};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  max-width: 100%;
`

export const CursorPointerWrapper = styled.div`
  cursor: pointer;
`

export const Hr = styled.hr<THr>`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => color || EColors.neutral_300};
  border: none;
  margin-top: ${({ marginTop }) => marginTop};
`

const Text = (type: TText) => styled(type)<TTextProps>`
  text-align: ${({ align }) => align || 'left'};
  color: ${({ color }) => color || EColors.neutral_800};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  max-width: 100%;
`

export const H1 = styled.h1`
  font-family: "Caslon 540 BT";
  font-size: 76px;

  color: ${EColors.primary_500};
  white-space: pre-line;
  @media screen and (max-width: 767px) {
    font-size: 36px;
    line-height: 106%;
  }
  @media screen and (min-width: 768px)and (max-width: 1439px) {
  font-size: calc((100vw - 768px) / (1439 - 768) * (76 - 36) + 36px);
  }
  @media screen and (min-width: 768px) {
  text-transform: uppercase;
  letter-spacing: 0.36px;
  }
`;

export const H2 = styled(Text("h2"))`
  font-weight:700;
  font-size: 68px;
  font-family: ${EFont.Alergeya};
  line-height: 100.8%;
  color: ${EColors.primary_500};
   @media screen and (max-width: 767px) {
    font-size: 36px;
    line-height: 106%;
  }
  @media screen and (min-width: 768px) and (max-width: 1439px) {
  font-size: calc((100vw - 460px) / (1439 - 460) * (68 - 30) + 30px);
  line-height: 108%;
  }
`;

export const H3 = styled(Text('h3'))`
  font-size: 42px;
  font-family: ${EFont.Alergeya};
  line-height: 120%;

  @media screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 116%;
  }
`

export const H4 = styled(Text('h4'))`
  font-size: 28px;
  font-family: ${EFont.Alergeya};
  line-height: 120%;
  
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: auto;
  }
`

export const PBody1 = styled(Text('p'))`
  font-size: 18px;
  font-family: ${EFont.AlergeyaSC};
  line-height: 132%;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const PText = styled(Text('p'))`
  font-size: 18px;
  font-family: 'Circe';
  line-height: 144%;
  text-indent:40px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 147%;
  }
`

export const PBody1SB = styled(PBody1)`
  font-weight: 600;
`

export const PBody2 = styled(Text('p'))`
  font-size: 16px;
  font-family: 'Alegreya SC', serif;
  line-height: 132%;

  @media screen and (max-width: 767px) {
    font-size: 14px;
    font-weight: 400;
  }
`

export const PBody2SB = styled(PBody2)`
  font-weight: 600;
`

export const ButtonText = styled.span`
  font-family: 'Alegreya SC', serif;
  font-weight: 500;
    font-size: 16px;
    line-height: 132%;
    color:${EColors.primary_500};
  @media screen and (max-width: 767px) {
      font-size: 14px;
    }  
`

