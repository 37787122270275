import { Svg } from 'common/assets'
import { useResolution } from 'common/hooks'
import { Styled } from 'components/Styled'
import { useTranslation } from 'react-i18next'
import { MottoWrapper, StyledSpan } from './styled'


export const Motto = () => {
  const { t } = useTranslation()
  const { isMinScreen768} = useResolution()
  const lang = localStorage.getItem("Localization") ? localStorage.getItem("Localization") :"uk";

  return (
    <MottoWrapper maxWidth={lang === "uk" ? "1181px" : "1240px"} >
      <Styled.H1>{t('motto.first')}<StyledSpan>{t('motto.second')}</StyledSpan></Styled.H1>
       <img src={Svg.SignTwo} />
      </MottoWrapper>  
  )
}
