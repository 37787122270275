import { EColors, EFont } from "common/styles";
import { FONT } from "common/utils";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
`;

export const FranchiseWrapper = styled.div`
  position: relative;
  display: flex;

  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.5s linear 0.3s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  & img{
    position: absolute;
    top:0;
    left:-30%;
    width: 92px;
    height: 68px;
  }
  @media screen and (min-width: 768px) and (max-width:999px)  {
 justify-content: center;
   & img{
    position: absolute;
    bottom: -100%;
    left: -40%;
    width: 14vw;
    height: auto;
  }
  }
  @media screen and (min-width: 1000px) {

   & img{
    position: absolute;
    top: 75%;
    left: -250px;
    width: 213px;
    height: 178px;
  }
  }
`;
export const FranchiseText = styled(Styled.H1)`
  font-weight: 600;
  font-size: 46px;
  color: #891515;
  opacity: 0.06;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;

  @media screen and (min-width: 768px) and (max-width: 1439px) {
    font-size: calc((100vw - 768px) / (1439 - 768) * (168 - 83) + 83px);
    margin-top: -12px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 172px;
    opacity: 0.04;
    margin-top: -36px;
  }
`;
